const BASE_API = 'https://services.pdg-dev.com';
export const environment = {
  production: false,
  name: 'default',
  walletApi: BASE_API + '/wallet_services/api',
  mauritanieApi : BASE_API + '/mauritanie_services/api',
  apiDgpsn: BASE_API + '/dgpsn_services/api',
  apiAuthUrl: BASE_API + '/auth_services/api',
  apiAdminUrl: BASE_API + '/admin_services/api',
  apiTransfertUrl: BASE_API + '/transfert_services/api',
  apiReseauUrl: BASE_API + '/reseau_services/api',
  apiBourseE: BASE_API + '/digimp_services/api',
  apiPas: BASE_API + '/pasm_services/api',
  apiDgse: BASE_API + '/dgse_services/api',
  apiAssurance: BASE_API + '/insurance_services/api',
  urlFront:'https://caisse.nt-albey.ne/#/sessions/signin',
  urlFrontforotNT:'https://caisse.nt-albey.ne/#/sessions/forgot',
  urlFrontfirstNT:'https://caisse.nt-albey.ne/#/sessions/updatePwd',
  urlFrontstandard:'https://caisse.pdg-dev.com/#/sessions/signin',
  urlFrontforot:'https://caisse.pdg-dev.com/#/sessions/forgot',
  urlFrontfirst:'https://caisse.pdg-dev.com/#/sessions/updatePwd',
};
